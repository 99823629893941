import React, { useEffect, useState } from "react";
import useCheckCat from "../../../../util/hooks/useCheckCat";
import { useApp } from "../../../../configuration/contextapi/context";
import { useNavigate, useParams } from "@cbmisorg/router";
import { Badge, Box, Button, Container, Grid, Loader, Modal, ModalBody, ModalHeader, Paper, Tabs, Typography } from "@cbmisorg/material";
import { Icon } from "@cbmisorg/icons";
import * as appVariables from "../../../../configuration/appVariables";
import { FormData, FormField } from "../../../../util/formApi/FormAPI";
import { manageTainingRegCtrl } from "./manageTainingReg.ctrl";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import ShredHeader from "../../../shared/shredHeader";
import * as appColor from "../../../../util/appStyle";
import NoResultImg from "../../../../../old/components/sharedUi/noResultImg/NoResultImg";
import { Avatar } from "@cbmisorg/client-app";

const strUserStatusKey = "bigUserStatusID";

function ManageTainingRegView() {
  const { appState, appDispatch } = useApp();
  const { strTrainingType } = useParams();
  const navigate = useNavigate();
  useCheckCat({ appState });

  const lang = appState.clientInfo.strLanguage;
  const labels = dictionary?.components?.stakeholders?.admin?.manageTainingReg;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    bigCurrentTab: appVariables.objUserStatus.Applicant,
    bigCurrentWorkTab: 0,
    lstUser: [],
    objStatistic: {
      [appVariables.objUserStatus.Applicant]: 0,
      [appVariables.objUserStatus.Approved]: 0,
      [appVariables.objUserStatus.Rejected]: 0,
    },
    blnOpenSortModal: false,
    blnOpenActionModal: false,

    blnOpenUserDetailModal: false,
    objUserDetail: {},

    intNumViewItem: 0,
    blnOpenIntroductoryVideo: false,
  });

  const fldSort = FormData({
    intGPA: { control: "list", options: [] },
    bigDegreeID: { control: "list", options: [] },
    bigMajorID: { control: "list", options: [] },
    bigWorkSector: {
      control: "list",
      options: [
        { key: "all", value: dictionary?.shared?.app?.all?.[lang] },
        { key: appVariables.workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title },
        { key: appVariables.workSectorID?.academic, value: dictionary?.shared?.app?.academic?.title },
        { key: appVariables.workSectorID?.student, value: dictionary?.shared?.app?.student?.title },
        { key: appVariables.workSectorID?.unEmployee, value: dictionary?.shared?.app?.unEmployee?.title },
      ],
      value:{ key: "all", value: dictionary?.shared?.app?.all?.[lang] },

    },
    bigEmploymentID: { control: "list", options: [] },
    bigCountryOfCitizenID: { control: "list", options: [] },
    bigCountryOfResidenceID: { control: "list", options: [] },
    strUniversityName: { control: "list", options: [] },
    bigAcademicYearID: { control: "list", options: [] },
    lstUserRole: { control: "list", options: [] },
    strFullNameSearch: { control: "text", objLanguage: false },
  });

  const fields = FormData({
    strUserEmail: { control: "text", objLanguage: false },
    strUserPhone: { control: "text", objLanguage: false },
    strFullName: { control: "text", objLanguage: false },
    dtmDob: { control: "date", objLanguage: false },
    strGovID: { control: "text", objLanguage: false },
    bigMajorID: { control: "list", options: [] },
    strAddress: { control: "text", objLanguage: false },
    bigDegreeID: { control: "list", options: [] },
    intGPA: { control: "list", options: [] },
    bigGender: { control: "list", options: [] },
    dtmGradDate: { control: "date", options: [] },
    bigEmploymentID: { control: "list", options: [] },
    bigCountryOfCitizenID: { control: "list", options: [] },
    bigCountryOfResidenceID: { control: "list", options: [] },
    strUniversityName: { control: "list", options: [] },
    bigAcademicYearID: { control: "list", options: [] },
    strWorkPlace: { control: "text", objLanguage: false },
    strPositionTitle: { control: "text", objLanguage: false },
  });

  const handlers = manageTainingRegCtrl({ appState, appDispatch, strTrainingType });
  const initData = handlers.initData({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fldSort, fields, strTrainingType });
  const onChangeCountry = handlers.onChangeCountry({ fldSort });
  const resetFilterFields = handlers.resetFilterFields({ fldSort, state: stateInfo, setState: setStateInfo });
  const filterUser = handlers.filterUser({ fldSort, state: stateInfo });
  const openUserDetail = handlers.openUserDetail({ fields, state: stateInfo, setState: setStateInfo, strTrainingType });
  const confirmUpdateUserInDB = handlers.confirmUpdateUserInDB({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fields, strTrainingType });
  const exportExcelFile = handlers.exportExcelFile({ stateInfo });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.pageTitle?.[strTrainingType]?.[lang]} lang={lang} blnIsLight={true} blnIsAllLight={true} />
      <Container pt-10 pb-3 sx={{ ".uicore_menu": { background: "#eee !important" } }}>
        {isLoading ? <Loader backdrop={true} color={appColor.App_Primary_Color} /> : null}
        <Grid container justifyContent={"center"} pt-5>
          {!stateInfo?.lstUser?.length ? (
            <NoResultImg />
          ) : (
            <>
              <Grid item xs="12">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs="12">
                    {strTrainingType === appVariables.objTrainingToSupport?.fullstack?.key ? (
                      <Tabs
                        mode="box"
                        activeColor={appColor.App_Primary_Opacity_Color}
                        p-0
                        dir={lang === "arb" ? "rtl" : "ltr"}
                        currentTab={1}
                        sx={{
                          overflowX: "auto",
                          flexWrap: "nowrap",
                          ".uicore_tab": {
                            minWidth: "fit-content",
                          },
                        }}
                        tabsContent={[
                          {
                            tabLabel: (
                              <Grid container justifyContent="center">
                                <Grid item xs="10" sm="auto" sx={{ whiteSpace: "nowrap" }}>
                                  {labels?.pendingUser?.[lang]}
                                </Grid>
                                <Grid item xs="2" sm="auto">
                                  <Typography
                                    as="body2"
                                    color="#fff"
                                    sx={{ background: appColor.App_Primary_Color, borderRadius: "25px", height: "30px", display: "flex", minWidth: "25px" }}
                                  >
                                    <b>{stateInfo?.objStatistic?.[appVariables.objUserStatus.Applicant] || 0}</b>
                                  </Typography>
                                </Grid>
                              </Grid>
                            ),
                            onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Applicant }),
                          },
                          {
                            tabLabel: (
                              <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs="10" sm="auto" sx={{ whiteSpace: "nowrap" }}>
                                  {labels?.approvedUser?.[lang]}
                                </Grid>
                                <Grid item xs="2" sm="auto" justifyContent="center">
                                  <Typography
                                    as="body2"
                                    color="#fff"
                                    sx={{ background: appColor.App_Dark_green, borderRadius: "25px", height: "30px", display: "flex", minWidth: "25px" }}
                                  >
                                    <b>{stateInfo?.objStatistic?.[appVariables.objUserStatus.Approved] || 0}</b>
                                  </Typography>
                                </Grid>
                              </Grid>
                            ),
                            onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Approved }),
                          },
                          {
                            tabLabel: (
                              <Grid container justifyContent="center">
                                <Grid item xs="10" sm="auto" sx={{ whiteSpace: "nowrap" }}>
                                  {labels?.excludedUser?.[lang]}
                                </Grid>
                                <Grid item xs="2" sm="auto" justifyContent="center">
                                  <Typography
                                    as="body2"
                                    color="#fff"
                                    sx={{ background: appColor?.App_Dark_orange, borderRadius: "25px", height: "30px", display: "flex", minWidth: "25px" }}
                                  >
                                    <b>{stateInfo?.objStatistic?.[appVariables.objUserStatus.Rejected] || 0}</b>
                                  </Typography>
                                </Grid>
                              </Grid>
                            ),
                            onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Rejected }),
                          },
                        ]}
                      />
                    ) : (
                      <Tabs
                        mode="box"
                        activeColor={appColor.App_Primary_Opacity_Color}
                        p-0
                        dir={lang === "arb" ? "rtl" : "ltr"}
                        currentTab={1}
                        tabsContent={[
                          {
                            tabLabel: (
                              <Grid container justifyContent="center">
                                <Grid item xs="9" sm="auto" sx={{ whiteSpace: "nowrap" }}>
                                  {labels?.allNumber?.[lang]}
                                </Grid>
                                <Grid item xs="2" sm="auto" justifyContent="center">
                                  <Box py-0 px-2 m-0 sx={{ background: appColor.App_Primary_Color }}>
                                    <Typography as="body2" color="#fff" p-0 m-0>
                                      <b>{stateInfo?.lstUser?.length || 0}</b>
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            ),
                            onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Applicant }),
                          },
                        ]}
                      />
                    )}
                  </Grid>
                  <Grid item xs={11} sx={{ background: "#ffffff" }} p-0>
                    <FormField
                      objHandler={fldSort.bigWorkSector}
                      icon={
                        <Avatar sx={{ width: "20px", height: "20px" }}>
                          <Typography as="caption" color="#000">
                            {fldSort?.bigWorkSector?.getValue()?.key === "all"
                              ? stateInfo?.lstUser?.filter((item) => stateInfo?.bigCurrentTab === item?.bigUserStatusID)?.length
                              : stateInfo?.lstUser?.filter(
                                  (item) => item?.jsnUserInfo?.bigWorkSector === fldSort?.bigWorkSector?.getValue()?.key && stateInfo?.bigCurrentTab === item?.bigUserStatusID
                                )?.length}
                          </Typography>
                        </Avatar>
                      }
                      dir={lang === "arb" ? "rtl" : "ltr"}
                    />
                  </Grid>
                  <Grid item xs="1">
                    <Button mode="link" icon="filterAlt" size="sm" p-1 onClick={() => setStateInfo({ ...stateInfo, blnOpenSortModal: true })} />
                  </Grid>
                  <Grid item xs={12} sx={{ background: "#ffffff" }} p-0>
                    <FormField objHandler={fldSort?.strFullNameSearch} endIcon={"search"} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </Grid>
              </Grid>

              {stateInfo?.lstUser?.map((item, index) => {
                if (index === 0) {
                  stateInfo.intNumViewItem = 0;
                }
                if (!filterUser(item)) {
                  if (!stateInfo.intNumViewItem && index >= stateInfo?.lstUser?.length - 1) {
                    return <NoResultImg key={index} xs={6} />;
                  }
                  return null;
                }
                ++stateInfo.intNumViewItem;

                return (
                  <Grid item xs="12" key={item?.bigUserID}>
                    <Paper
                      sx={{
                        width: "100%",
                        background: !item?.jsnUserInfo?.blnIsSeen?.[strTrainingType]
                          ? appColor.App_Light_blue
                          : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                          ? appColor.App_Light_Color
                          : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                          ? appColor.App_Light_green
                          : appColor.App_Light_Gray,
                        color:
                          String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                            ? appColor.App_Primary_Color
                            : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                            ? appColor.App_Dark_green
                            : appColor.App_Gray_Color,
                      }}
                    >
                      <Grid container alignContent="center" alignItems="center">
                        <Grid item xs="1" md="auto">
                          {item?.jsnUserInfo?.blnIsSeen?.[strTrainingType] === true ? (
                            <Icon icon={"AccountBox"} size="24px" pt-1 color="current-color" />
                          ) : (
                            <Badge mode="dot" sx={{ background: "var(--cbmis-success) !important" }}>
                              <Icon icon={"AccountBox"} size="24px" pt-1 color={appColor?.App_Primary_Color} />
                            </Badge>
                          )}
                        </Grid>
                        <Grid item xs="7" md="9" lg="11" pb-0>
                          <Typography as="body2" pb-0 color="current-color" className="traineeName">
                            {typeof item?.jsnUserInfo?.strFullName === "object" ? item?.jsnUserInfo?.strFullName?.[lang] : item?.jsnUserInfo?.strFullName}
                          </Typography>
                        </Grid>
                        <Grid item xs="2" md="1" lg="auto" px-0>
                          <Button
                            icon="visibility"
                            p-0
                            onClick={() => navigate(`/training/${item?.strTrainingTypeCode}`, { state: { userInfo: item } })}
                            color={
                              String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                                ? appColor.App_Primary_Color
                                : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                                ? appColor.App_Dark_green
                                : appColor.App_Gray_Color
                            }
                          />
                        </Grid>
                        <Grid item xs="2" md="1" lg="auto" px-0 justifyContent="flex-end">
                          <Button
                            icon="checkBoxOutlined"
                            p-0
                            mx-2
                            onClick={openUserDetail(item)}
                            color={
                              String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                                ? appColor.App_Primary_Color
                                : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                                ? appColor.App_Dark_green
                                : appColor.App_Gray_Color
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Container>

      <Modal open={stateInfo.blnOpenSortModal} size={{ xs: "lg", sm: "xl", md: "md" }} eventClose={() => setStateInfo({ ...stateInfo, blnOpenSortModal: false })}>
        <ModalHeader>
          <Typography as="subtitle2" className="textcenter">
            {labels?.filterBtn?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody sx={{ ".uicore_menu": { background: "#eee !important" } }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormField objHandler={fldSort.bigCountryOfCitizenID} icon="flag" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField objHandler={fldSort.bigCountryOfResidenceID} icon="LocationOn" onChange={onChangeCountry} dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.strUniversityName} icon="locationCity" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigMajorID} icon="localLibrary" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigEmploymentID} icon="work" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigDegreeID} icon="historyEdu" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigAcademicYearID} icon="school" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.intGPA} icon="insertChartOutlined" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" mt-4>
            <Grid item>
              <Button icon="filterList" size="sm" label={labels?.filterBtn?.[lang]} p-1 onClick={() => setStateInfo({ ...stateInfo, blnOpenSortModal: false })} />
            </Grid>
            <Grid item>
              <Button mode="outlined" label={dictionary?.shared?.btn?.rest?.[lang]} icon="restartAlt" size="sm" onClick={resetFilterFields} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
      <Modal
        open={stateInfo?.blnOpenActionModal}
        eventClose={() => {
          setStateInfo({ ...stateInfo, blnOpenActionModal: false });
        }}
        size={{ xs: "lg", sm: "xl", md: "md" }}
      >
        <ModalHeader>
          <Typography as="subtitle2" className="textCenter">
            {dictionary?.shared?.confirmationMsg?.procedure?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justifyContent={"center"}>
            {String(stateInfo.objUserDetail?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant) || !stateInfo?.objUserDetail?.[strUserStatusKey] ? (
              <Grid item xs="12">
                <Typography as="body2">{labels.note?.[lang]}</Typography>
              </Grid>
            ) : String(stateInfo.objUserDetail?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved) ? (
              <Grid item xs="12">
                <Typography as="body2">{labels.noteApproved?.[lang]}</Typography>
              </Grid>
            ) : (
              <Grid item xs="12">
                <Typography as="body2">{labels.notExclude?.[lang]}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3} justifyContent={"center"}>
            {String(stateInfo?.objUserDetail?.[strUserStatusKey]) === String(appVariables?.objUserStatus?.Approved) ? (
              <Grid item />
            ) : (
              <Grid item>
                <Button
                  label={labels?.approve?.[lang]}
                  color="success"
                  size="sm"
                  sx={{ width: "55px !important" }}
                  onClick={confirmUpdateUserInDB(appVariables.objUserStatus.Approved)}
                />
              </Grid>
            )}
            {String(stateInfo?.objUserDetail?.[strUserStatusKey]) === String(appVariables?.objUserStatus?.Rejected) ? null : (
              <Grid item>
                <Button
                  label={labels?.exception?.[lang]}
                  color="error"
                  size="sm"
                  sx={{ width: "55px !important" }}
                  onClick={confirmUpdateUserInDB(appVariables.objUserStatus.Rejected)}
                />
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default ManageTainingRegView;
