import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as XLSX from "xlsx";
import { generateQueries , objTableAttributes} from "../../../../graphql/schema/GenerateQuery";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import * as appVariables from "../../../../configuration/appVariables";
import * as appFunctions from "../../../../util/appFunctions";

const tblUser = generateQueries("tblUser");

const strPageInfo = "@src/client/component/stakeholders/admin/manageTainingReg/manageTainingReg.ctrl.js";

export function manageTainingRegCtrl({ appState, appDispatch, strTrainingType }) {
  const lang = appState?.clientInfo?.strLanguage;
  const allLang = { eng: "All", arb: "الجميع" };
  const strUserStatusKey = "bigUserStatusID";

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fldSort, fields, strTrainingType }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang]);
            return;
          }

          setIsLoading(true);
          state.blnIsInitDone = true;

          const result = await tblUser(strPageInfo, appState, "aaiotAppUserAll", {
            objectCondition: {
              ...(strTrainingType === appVariables?.objTrainingToSupport?.fullstack?.key
                ? { blnIsStudent: true }
                : strTrainingType === appVariables.objTrainingToSupport?.researcher?.key
                ? { blnIsResearcher: true }
                : strTrainingType === appVariables.objTrainingToSupport?.volunteer?.key
                ? { blnIsVolunteer: true }
                : strTrainingType === appVariables.objTrainingToSupport?.mdeas?.key
                ? { blnIsMDEAS: true }
                : { blnIsStartUp: true }),
            },
            arrAttributes: objTableAttributes.tblUser.full.filter(
              (field) =>
                field !== "strUserPassword" &&
                field !== "txtLoginKeys" &&
                field !== "txtLoginIP" &&
                field !== "txtPushSubscription" &&
                field !== "intNumLoginAttempt" &&
                field !== "blnIsAccountLocked" &&
                field !== "dtmAccountLocked" &&
                field !== "strLastLoginUserAgent" &&
                field !== "strLastLoginIPAddress" &&
                field !== "txtChangePasswordInfo" &&
                field !== "txtLoginHistoryInfo"
            ),
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.lstUser = Array.isArray(result?.response) ? (result?.response).reverse() : [];

          for (let i = 0; i < state?.lstUser?.length; i++) {
            const objUserInfo = state?.lstUser[i];

            if (String(appVariables?.objUserStatus?.Applicant) === String(objUserInfo?.[strUserStatusKey])) {
              ++state.objStatistic[appVariables?.objUserStatus?.Applicant];
            }
            if (String(appVariables?.objUserStatus?.Approved) === String(objUserInfo?.[strUserStatusKey])) {
              ++state.objStatistic[appVariables?.objUserStatus?.Approved];
            }
            if (String(appVariables?.objUserStatus?.Rejected) === String(objUserInfo?.[strUserStatusKey])) {
              ++state.objStatistic[appVariables?.objUserStatus?.Rejected];
            }

          }

          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstCountryOption = [];
          let lstDegreeOption = [];
          let lstEmploymentOption = [];
          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGPAOption = [];
          let lstUniversityOption = [];
          let lstGenderOption = [];

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstEmploymentOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gpa || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGPAOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions(lstMajorOption);
          fields.bigDegreeID.setOptions(lstDegreeOption);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigEmploymentID.setOptions(lstEmploymentOption);
          fields.bigCountryOfCitizenID.setOptions(lstCountryOption);
          fields.bigCountryOfResidenceID.setOptions(lstCountryOption);
          fields.strUniversityName.setOptions(lstUniversityOption);
          fields.bigAcademicYearID.setOptions(lstAcademicYearOption);
          fields.intGPA.setOptions(lstGPAOption);

          let lstCountryOptionSort = [...lstCountryOption];
          let lstDegreeOptionSort = [...lstDegreeOption];
          let lstEmploymentOptionSort = [...lstEmploymentOption];
          let lstMajorOptionSort = [...lstMajorOption];
          let lstAcademicYearOptionSort = [...lstAcademicYearOption];
          let lstGPAOptionSort = [...lstGPAOption];
          let lstUniversityOptionSort = [...lstUniversityOption];

          lstCountryOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstDegreeOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstEmploymentOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstMajorOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstAcademicYearOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstGPAOptionSort.unshift({ key: "all", value: allLang?.[lang] });
          lstUniversityOptionSort.unshift({ key: "all", value: allLang?.[lang] });

          fldSort.bigCountryOfCitizenID.setOptions(lstCountryOptionSort);
          fldSort.bigCountryOfResidenceID.setOptions(lstCountryOptionSort);
          fldSort.bigDegreeID.setOptions(lstDegreeOptionSort);
          fldSort.bigEmploymentID.setOptions(lstEmploymentOptionSort);
          fldSort.bigMajorID.setOptions(lstMajorOptionSort);
          fldSort.bigAcademicYearID.setOptions(lstAcademicYearOptionSort);
          fldSort.intGPA.setOptions(lstGPAOptionSort);
          fldSort.strUniversityName.setOptions(lstUniversityOptionSort);
          fldSort.lstUserRole.setOptions(
            [
              { key: "all", value: allLang?.[lang] },
              { key: "student", value: "student" },
              { key: "entrepreneur", value: "entrepreneur" },
              { key: "manager", value: "manager" },
            ],
            {
              student: { eng: "Student", arb: "طالب" },
              entrepreneur: { eng: "Entrepreneur", arb: "ريادي" },
              manager: { eng: "Manager", arb: "مدير" },
            }
          );

          fldSort.bigCountryOfCitizenID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigCountryOfResidenceID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigDegreeID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigEmploymentID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigMajorID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.bigAcademicYearID.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.intGPA.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.strUniversityName.setValue({ key: "all", value: allLang?.[lang] });
          fldSort.lstUserRole.setValue({ key: "all", value: allLang?.[lang] });

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },
    onChangeCountry:
      ({ fldSort }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [{ key: "all", value: "all" }];
        let objUniversityOptionLang = { all: { eng: "All", value: "كل المتدربين" } };

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.buttons.otherBtn;

        fldSort.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },
    resetFilterFields:
      ({ state, setState, fldSort }) =>
      (event) => {
        fldSort.bigCountryOfCitizenID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigCountryOfResidenceID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigDegreeID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigEmploymentID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigMajorID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.bigAcademicYearID.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.intGPA.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.strUniversityName.setValue({ key: "all", value: allLang?.[lang] });
        fldSort.lstUserRole.setValue({ key: "all", value: allLang?.[lang] });

        state.blnOpenSortModal = false;
        setState({ ...state });
      },
    filterUser:
      ({ fldSort, state }) =>
      (objUserInfo) => {
        const intGPA = fldSort?.intGPA?.getValue()?.key;
        const bigDegreeID = fldSort?.bigDegreeID?.getValue()?.key;
        const bigMajorID = fldSort?.bigMajorID?.getValue()?.key;
        const bigEmploymentID = fldSort?.bigEmploymentID?.getValue()?.key;
        const bigCountryOfCitizenID = fldSort?.bigCountryOfCitizenID?.getValue()?.key;
        const bigCountryOfResidenceID = fldSort?.bigCountryOfResidenceID?.getValue()?.key;
        const strUniversityName = fldSort?.strUniversityName?.getValue()?.key;
        const bigAcademicYearID = fldSort?.bigAcademicYearID?.getValue()?.key;
        const bigWorkSector = fldSort?.bigWorkSector?.getValue()?.key;
        const intUserRoleFK = fldSort.lstUserRole?.getValue()?.key;
        const strFullName = fldSort?.strFullNameSearch?.getValue();

        if (state?.bigCurrentTab) {
          if (
            String(state?.bigCurrentTab) !== String(objUserInfo?.[strUserStatusKey]) &&
            !(String(state?.bigCurrentTab) === String(appVariables?.objUserStatus?.Applicant) && !objUserInfo?.[strUserStatusKey])
          ) {
            return null;
          }
        }

        if (intUserRoleFK !== "all") {
          if (String(intUserRoleFK) === "student" && !objUserInfo?.blnIsStudent) {
            return null;
          }
          if (String(intUserRoleFK) === "entrepreneur" && !objUserInfo?.blnIsStartUp) {
            return null;
          }
          if (String(intUserRoleFK) === "manager" && !objUserInfo?.blnIsManager) {
            return null;
          }
        }

        if (bigAcademicYearID !== "all") {
          if (String(bigAcademicYearID) !== String(objUserInfo?.jsnUserInfo?.bigAcademicYearID)) {
            return null;
          }
        }

        if (intGPA !== "all") {
          if (String(intGPA) !== String(objUserInfo?.jsnUserInfo?.bigGpaID)) {
            return null;
          }
        }
        if (bigDegreeID !== "all") {
          if (String(bigDegreeID) !== String(objUserInfo?.jsnUserInfo?.bigDegreeID)) {
            return null;
          }
        }
        if (bigMajorID !== "all") {
          if (String(bigMajorID) !== String(objUserInfo?.jsnUserInfo?.strMajor)) {
            return null;
          }
        }
        if (bigEmploymentID !== "all") {
          if (String(bigEmploymentID) !== String(objUserInfo?.jsnUserInfo?.bigEmploymentID)) {
            return null;
          }
        }
        if (bigCountryOfCitizenID !== "all") {
          if (String(bigCountryOfCitizenID) !== String(objUserInfo?.jsnUserInfo?.bigCountryOfCitizenID)) {
            return null;
          }
        }
        if (bigCountryOfResidenceID !== "all") {
          if (String(bigCountryOfResidenceID) !== String(objUserInfo?.jsnUserInfo?.bigCountryOfResidenceID)) {
            return null;
          }
        }
        if (strUniversityName !== "all") {
          if (String(strUniversityName) !== String(objUserInfo?.jsnUserInfo?.strUniversityName)) {
            return null;
          }
        }
        if (bigWorkSector !== "all") {
          if (String(bigWorkSector) !== String(objUserInfo?.jsnUserInfo?.bigWorkSector)) {
            return null;
          }
        }

        if (strFullName && String(strFullName)?.length) {
          if (
            !String(objUserInfo?.jsnUserInfo?.strFullName?.arb).toLocaleLowerCase().includes(String(strFullName).toLocaleLowerCase()) &&
            !String(objUserInfo?.jsnUserInfo?.strFullName?.eng).toLocaleLowerCase().includes(String(strFullName).toLocaleLowerCase())
          ) {
            return null;
          }
        }

        return true;
      },

    openUserDetail:
      ({ state, setState, fields, strTrainingType }) =>
      (objUserDetail) =>
      async (event) => {
        try {
          if (
            !objUserDetail?.jsnUserInfo?.blnIsSeen?.[strTrainingType] &&
            objUserDetail?.bigUserID &&
            appState?.userInfo?.jsnUserInfo?.intUserRoleFK !== appVariables.objUserRole?.RootAdmin
          ) {
            if (typeof objUserDetail.jsnUserInfo.blnIsSeen !== "object") {
              objUserDetail.jsnUserInfo.blnIsSeen = {};
            }
            objUserDetail.jsnUserInfo.blnIsSeen[strTrainingType] = true;
            await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
              objectCondition: {
                bigUserID: objUserDetail?.bigUserID,
              },
              objectInput: {
                jsnUserInfo: objUserDetail.jsnUserInfo,
              },
            });
          }
          state.blnOpenActionModal = true;
          state.objUserDetail = objUserDetail;
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "openUserDetail-Error");
        }
      },
    openIntroductoryVideo:
      ({ state, setState }) =>
      () => {
        setState({ ...state, blnOpenIntroductoryVideo: !state?.blnOpenIntroductoryVideo });
      },
    onChangeCountryForUser:
      ({ fields }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [];
        let objUniversityOptionLang = {};

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.buttons.otherBtn;

        fields.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },
    updateUserInDB:
      ({ state, setState, isLoading, setIsLoading, fields }) =>
      (bigUserStatusID = null) =>
      async (event) => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          if (bigUserStatusID && state.objUserDetail?.[strUserStatusKey] !== bigUserStatusID) {
            --state.objStatistic[state.objUserDetail?.[strUserStatusKey]];
            ++state.objStatistic[bigUserStatusID];
            state.objUserDetail[strUserStatusKey] = bigUserStatusID;
          }
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectCondition: {
              bigUserID: state?.objUserDetail?.bigUserID,
            },

            objectInput: {
              jsnUserInfo: {
                ...state.objUserDetail.jsnUserInfo,
                objUserStatusKey: {
                  [strTrainingType]: bigUserStatusID,
                },
              },
              bigUserStatusID: bigUserStatusID,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.blnOpenUserDetailModal = false;
          setIsLoading(false);
          setState({ ...state });
          if (bigUserStatusID) {
            Alert.viewAlert(dictionary?.components?.stakeholders?.admin?.manageTainingReg?.traineeStatusUpdateSuccess?.[lang], "success");
          } else {
            Alert.viewAlert(dictionary?.components?.stakeholders?.admin?.manageTainingReg?.traineeInfoUpdateSuccess?.[lang], "success");
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "updateUserInDB-Error");
        }
      },
    confirmUpdateUserInDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType }) =>
      (bigUserStatusID = null, isConfirmation = false) =>
      () => {
        if (bigUserStatusID && isConfirmation) {
          Confirmation.viewConfirmation(
            dictionary?.components?.stakeholders?.admin?.manageTainingReg?.confirmUpdateTrainee?.[lang],
            () => setState({ ...state, blnOpenActionModal: true, blnOpenUserDetailModal: false }),
            handler.updateUserInDB({ state, setState, isLoading, setIsLoading, fields })(bigUserStatusID),
            dictionary?.shared?.confirmationMsg?.procedure?.[lang],
            dictionary?.shared?.confirmationMsg?.noProcedure?.[lang]
          );
        } else {
          state.blnOpenActionModal = false;
          handler.updateUserInDB({ state, setState, isLoading, setIsLoading, fields })(bigUserStatusID)();
        }
      },

    exportExcelFile:
      ({ stateInfo }) =>
      () => {
        var workbook = XLSX.utils.book_new();
        var worksheet_data = [
          lang === "arb"
            ? [
                "الرقم التعريفي",
                "اسم الفاعلية",
                "اسم الندوة أو ورشة العمل",
                "تاريخ الندوة أو ورشة العمل",
                "الفئة المستهدفة",
                "الاسم",
                "البريد الالكتروني",
                "قطاع العمل",
                "مكان العمل",
                "تاريخ التسجيل",
              ]
            : [
                "Id",
                "Name of the event",
                "Name of the seminar or workshop",
                "Date of the seminar or workshop",
                "Target group",
                "Name",
                "Email",
                "Work sector",
                "Workplace",
                "Registration date",
              ],
        ];

        const objCat = appFunctions.getCategoryCache();

        (stateInfo?.lstUser || [])?.forEach((child) => {
          let bigActivityGuestID = child?.bigActivityGuestID || "";
          let strActivityGuestName = appFunctions.appCategoryGetValue(objCat?.Root?.activity, child?.bigActivityType)?.[lang] || "";
          let strActivityGuestKey = appFunctions.appCategoryGetKey(objCat?.Root?.activity, child?.bigActivityType);

          let strFullName = child?.jsnGuestInfo?.strFullName?.[lang] || "";
          let strEmail = child?.strUserEmail || "";
          let strWorkSector = appVariables.workSectorIDLabel?.[child?.bigWorkSector]?.[lang] || "";
          let strPlaceName = child?.strPlaceName || "";
          let dtmCreatedDate = appFunctions.getDateUSFormat(child?.dtmCreatedDate) || "";

          let letUserActivityReg = [];

          let lstSubjectUser = Array.isArray(child?.jsnGuestInfo?.objSubject) ? child?.jsnGuestInfo?.objSubject : [];
          lstSubjectUser.forEach((subject) => {
            let strSubjectKey = appFunctions.appCategoryGetKey(objCat?.Root?.activity?.[strActivityGuestKey]?.subject, subject);
            let strSubjectName = appFunctions.appCategoryGetValue(objCat?.Root?.activity?.[strActivityGuestKey]?.subject, strSubjectKey)?.[lang] || "";
            let strSubjectDate = new Date(objCat?.Root?.activity?.[strActivityGuestKey]?.subject?.[strSubjectKey]?.date?.value?.eng).toLocaleDateString();
            let strSubjectTarget = objCat?.Root?.activity?.[strActivityGuestKey]?.subject?.[strSubjectKey]?.targeted?.value?.[lang] || "";

            letUserActivityReg.push([
              bigActivityGuestID,
              strActivityGuestName,
              strSubjectName,
              strSubjectDate,
              strSubjectTarget,

              strFullName,
              strEmail,
              strWorkSector,
              strPlaceName,
              dtmCreatedDate,
            ]);
          });

          worksheet_data?.push(...letUserActivityReg);
        });

        var worksheet = XLSX.utils.aoa_to_sheet(worksheet_data);
        worksheet["!autofilter"] = { ref: "F1" };
        workbook.SheetNames.push("registered_in_activities");
        workbook.Sheets["registered_in_activities"] = worksheet;
        XLSX.writeFile(workbook, `registered_in_activities_${appFunctions.getDateUSFormat(appFunctions?.getCurrentDate())}.xlsx`);
      },
  };

  return handler;
}
